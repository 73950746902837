/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }


body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Custom button styles */
button {
  background-color: #d3d3d3; /* Light gray background */
  border: none; /* Remove borders */
  color: #000; /* Black text */
  padding: 0.25em 0.5em; /* Padding with em units */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button appear inline */
  font-size: 0.5em; /* Set font size in em units */
  margin: 0.25em; /* Margin in em units */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 0.5em; /* Rounded corners in em units */
}

  /* Change button color on hover */
  button:hover {
    background-color: #e8e8e8; /* Even lighter gray background on hover */
  }

/* Media query for small screens (mobile devices) */
@media (max-width: 1100px) {

  div {
    font-size: 0.9em; /* Slightly smaller font size */
  }

  h1 {
    font-size: 1.5em; /* Smaller font size for h1 on mobile */
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 0.9em;
  }

  button {
    font-size: 0.75em; /* Larger font size for button text */
  }

  nav ul li a {
    font-size: 0.8em;
  }

  header {
    font-size: 0.9em;
  }

  input {
      font-size: 0.9em;
  }
}

/* Media query for large screens (desktops) */
@media (min-width: 2100px) {

  div {
    font-size: 1em; /* Slightly larger font size */
  }

  h1 {
    font-size: 2.5em; /* Larger font size for h1 on large screens */
  }

  h2 {
    font-size: 2.25em;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.75em;
  }

  h5 {
    font-size: 1.5em;
  }

  h6 {
    font-size: 1.25em;
  }

  button {
    font-size: 0.85em; /* Slightly larger button font size */
  }

  nav ul li a {
    font-size: 1em;
  }

  header {
    font-size: 1em;
  }

  body {
    font-size: 1em;
  }
}
