/* Colors, using same names and values as in highcharts.css */
:root {
    --highcharts-background-color: #ffffff;
    --highcharts-neutral-color-100: #000000;
    --highcharts-neutral-color-80: #333333;
    --highcharts-neutral-color-60: #666666;
    --highcharts-neutral-color-20: #cccccc;
    --highcharts-neutral-color-10: #e6e6e6;
    --highcharts-neutral-color-3: #f7f7f7;
    --highcharts-highlight-color-80: #335cad;
}

.highcharts-popup.highcharts-annotation-toolbar {
    right: 10%;
    left: auto;
    height: 40px;
    overflow: hidden;
    padding-right: 40px;
    width: auto;
    min-width: 0;
}

.highcharts-annotation-toolbar button {
    margin-top: 0;
    cursor: pointer;
    background-color: var(--highcharts-neutral-color-3);
}

.highcharts-popup.highcharts-annotation-toolbar > p {
    margin: 0;
    display: block;
    float: left;
    padding: 12px;
}

.highcharts-popup {
    font-family: Helvetica, Arial, sans-serif;
    background-color: var(--highcharts-background-color);
    color: var(--highcharts-neutral-color-60);
    display: none;
    font-size: 0.9em;
    max-height: 90%;
    top: 5%;
    left: 15%;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 75%;
    min-width: 300px;
    max-width: 600px;
    position: absolute;
    z-index: 100;
    -webkit-box-shadow: 0 0 8px 0 rgba(61, 61, 61, 0.3);
    -moz-box-shadow: 0 0 8px 0 rgba(61, 61, 61, 0.3);
    box-shadow: 0 0 8px 0 rgba(61, 61, 61, 0.3);
}

.highcharts-popup div,
.highcharts-popup span {
    box-sizing: border-box;
}

.highcharts-popup input,
.highcharts-popup label,
.highcharts-popup select {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.highcharts-popup input {
    border: 1px solid var(--highcharts-neutral-color-10);
    border-radius: 0.3rem;
    padding: 5px;
    width: 100%;
}

.highcharts-popup-lhs-col,
.highcharts-popup-rhs-col {
    height: 100%;
    float: left;
    overflow-y: auto;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full {
    width: 100%;
    overflow-x: hidden;
    height: calc(100% - 104px);
    border: none;
    padding: 20px;
    padding-bottom: 10px;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full + .highcharts-popup-bottom-row {
    width: 100%;
}

.highcharts-popup-lhs-col {
    clear: both;
    width: 30%;
    border-right: 1px solid var(--highcharts-neutral-color-10);
}

.highcharts-popup-bottom-row {
    float: left;
    padding: 0 20px;
    padding-bottom: 12px;
    width: 100%;
    border-top: 1px solid var(--highcharts-neutral-color-10);
}

.highcharts-popup-rhs-col {
    width: 70%;
    height: calc(100% - 40px);
    padding: 20px;
}

.highcharts-popup-rhs-col-wrapper {
    width: 100%;
    overflow-x: hidden;
}

.highcharts-popup-rhs-col-wrapper h3 {
    margin-top: 0;
    padding-bottom: 0;
}

.highcharts-bindings-wrapper ul.highcharts-indicator-list,
.highcharts-indicator-list {
    float: left;
    color: var(--highcharts-neutral-color-60);
    height: calc(100% - 150px);
    width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 15px 0;
}

button.highcharts-indicator-list-item {
    cursor: pointer;
    padding: 5px 20px;
    width: 100%;
    height: auto;
    overflow: hidden;
    word-break: break-all;
    box-sizing: border-box;
    background-color: var(--highcharts-background-color);
    text-align: left;
}

.highcharts-indicator-list li {
    width: 100%;
    padding: 0;
    height: auto;
}

.highcharts-popup > button.highcharts-tab-item {
    margin: 0;
    background-color: var(--highcharts-neutral-color-3);
    cursor: pointer;
    display: block;
    float: left;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
}

button.highcharts-tab-item.highcharts-tab-item-active {
    background-color: var(--highcharts-neutral-color-10);
}

.highcharts-tab-item-content {
    display: none;
    float: left;
    height: 100%;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid var(--highcharts-neutral-color-10);
}

.highcharts-tab-item-show {
    display: block;
}

.highcharts-popup > .highcharts-popup-close {
    margin: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: var(--highcharts-background-color);
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    color: var(--highcharts-neutral-color-80);
}

.highcharts-popup-close:hover,
.highcharts-popup button:hover,
.highcharts-popup button.highcharts-annotation-edit-button:hover,
.highcharts-popup button.highcharts-annotation-remove-button:hover {
    background-color: var(--highcharts-neutral-color-10);
}

div.highcharts-popup-rhs-col > button {
    float: right;
}

.highcharts-popup button {
    border: none;
    margin: 0 5px 0 0;
}

button.highcharts-tab-item.highcharts-tab-disabled {
    color: var(--highcharts-neutral-color-20);
}

button.highcharts-tab-item.highcharts-tab-disabled:hover {
    background-color: var(--highcharts-neutral-color-3);
}

/* annotation edit small popup */
.highcharts-popup button.highcharts-annotation-edit-button,
.highcharts-popup button.highcharts-annotation-remove-button {
    width: 20px;
    height: 40px;
    padding: 20px;
}

.highcharts-popup button.highcharts-annotation-edit-button {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
}

.highcharts-popup button.highcharts-annotation-remove-button {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
}

.highcharts-popup .highcharts-annotation-title {
    display: block;
    float: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    width: 100%;
}

.highcharts-popup .highcharts-popup-main-title {
    border-bottom: 1px solid var(--highcharts-neutral-color-10);
    margin: 0;
    padding: 8px 0 6px 20px;
}

.highcharts-indicator-title {
    float: left;
    padding-bottom: 15px;
}

.highcharts-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.highcharts-input-search-indicators-label {
    text-align: center;
    font-weight: bold;
    color: var(--highcharts-neutral-color-100);
    margin-top: 0.5rem;
}

input.highcharts-input-search-indicators {
    width: 80%;
    margin: 0 auto;
    float: none;
    border-color: #949494;
}

.highcharts-popup a.clear-filter-button {
    margin: 0 auto;
    display: none;
    color: var(--highcharts-highlight-color-80);
    background-color: var(--highcharts-background-color);
    border: 1px solid var(--highcharts-highlight-color-80);
    margin-top: 10px;
}

.highcharts-popup a.clear-filter-button:hover {
    color: var(--highcharts-background-color);
    background-color: var(--highcharts-highlight-color-80);
    cursor: pointer;
}
